export default class quillCounter {
    constructor(quill, options) {
      this.quill = quill;
      this.options = options;
      this.container = document.querySelector(options.container);
      quill.on('text-change', this.update.bind(this));
      this.update();  // Account for initial contents
    }

    calculate() {

      if (this.options.unit === 'word') {
        let text = this.quill.getText();
        text = text.trim();
        // Splitting empty text returns a non-empty array
        return text.length > 0 ? text.split(/\s+/).length : 0;
      } else {
        // return text.length-1;
        return this.getCount();

      }
    }

    update() {
      this.validate();
    }

    validate(){
      // console.log('validate');
      var length = this.calculate();
      var label = this.options.unit;
      var max = this.options.max;
      var min = this.options.min ? this.options.min : 0;
      let error = null;

      if(length > max){
        error = length + ' ' + label + ' Максимум '+max +' '+ label;
      }

      if(length < min){
        error = length + ' ' + label + ' Минимум '+min +' '+ label;
      }

      if(error){
        this.container.innerText = error;
        this.container.classList.add("error");
      }
      else{
        this.container.innerText = length + ' ' + label;
        this.container.classList.remove("error");
      }
    }

    getCount(){
        let text = this.quill.getText();
        return text.replace(/\r?\n/g, "").length;
    }
  }
